import {Component, inject, OnInit, ViewChild} from '@angular/core';
import {I18nService} from '~/services/common/i18n.service';
import { environment } from '../environments/environment';
import { MatSidenav, MatSidenavContainer, MatSidenavContent } from '@angular/material/sidenav';
import {BreakpointObserver} from '@angular/cdk/layout';
import packageJson from '../../package.json';
import { Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import {ErrorService} from '~/services/error.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { MatNavList } from '@angular/material/list';
import { ToolbarComponent } from '~/components';
import { NgIf, NgClass, UpperCasePipe } from '@angular/common';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        ToolbarComponent,
        MatSidenavContainer,
        MatSidenav,
        NgClass,
        MatNavList,
        RouterLink,
        RouterLinkActive,
        MatIcon,
        MatSidenavContent,
        RouterOutlet,
        UpperCasePipe,
        TranslateModule
    ],
})
export class AppComponent implements OnInit {
  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;
  isMobile = true;
  MENU_COLLAPSED = 'menu-collapsed';
  isCollapsed: boolean = localStorage.getItem(this.MENU_COLLAPSED) === 'true';
  versionPackage: string = packageJson.version;

  i18nService = inject(I18nService);
  router = inject(Router);
  observer = inject(BreakpointObserver);
  errorService = inject(ErrorService);

  isLogoutPage(): boolean {
    return this.router.url === '/callback-logout';
  }
  isLoginPage(): boolean {
    return this.router.url === '/login';
  }

  ngOnInit() {
    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);

    this.observer.observe(['(max-width: 800px)']).subscribe((screenSize) => {
      this.isMobile = screenSize.matches;
    });
  }

  toggleMenu() {
    if(this.isMobile){
      this.sidenav.toggle();
      this.isCollapsed = false;
    } else {
      this.sidenav.open();
      this.isCollapsed = !this.isCollapsed;
    }
    localStorage.setItem(this.MENU_COLLAPSED, this.isCollapsed ? 'true' : 'false');
  }
}
