import { enableProdMode, importProvidersFrom } from '@angular/core';
import { environment } from './environments/environment';
import { AppComponent } from '~/app.component';
import { Observable } from 'rxjs';
import { MatOptionModule } from '@angular/material/core';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { provideRouter, Routes } from '@angular/router';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {BrowserAnimationsModule, provideAnimations, provideNoopAnimations} from '@angular/platform-browser/animations';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { AuthInterceptor, AuthorizationGuard } from '~/auth';
import {
  HTTP_INTERCEPTORS,
  withInterceptorsFromDi,
  provideHttpClient,
  HttpClient,
  HttpClientModule
} from '@angular/common/http';
import { I18nService } from '~/services/common/i18n.service';
import { TranslateService, TranslatePipe, TranslateDirective, TranslateModule, TranslateLoader } from '@ngx-translate/core';
import {SETTINGS} from '~/models';
import settings from './app/appsettings.json';

import 'blockly/blocks';  // needed if you want to use the blockly default blocks

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export class TranslateHttpLoader implements TranslateLoader {
  constructor(private http: HttpClient, public prefix = '/assets/i18n/', public suffix = '.json') {}

  /**
   * Gets the translations from the server
   */
  public getTranslation(lang: string): Observable<object> {
    return this.http.get(`${this.prefix}${lang}${this.suffix}`);
  }
}

const defaultPage = 'addSimple';
const routes: Routes = [
    { path: '', pathMatch: 'full', redirectTo: defaultPage },
    { path: 'oauth2callback', pathMatch: 'full', redirectTo: defaultPage },
    {
        path: 'callback-logout',
        loadComponent: () => import('./app/components/pages/logout/logout.component').then((m) => m.LogoutComponent),
    },
    {
        path: 'computations',
        loadComponent: () => import('./app/components/pages/computationsList/computationsList.component').then((m) => m.ComputationsListComponent),
        canActivate: [AuthorizationGuard],
    },
    {
        path: 'addSimple',
        loadComponent: () => import('./app/components/pages/add-computation/add-computation.component').then((m) => m.AddComputationComponent),
        canActivate: [AuthorizationGuard],
    },
    {
        path: 'addAdvanced',
        loadComponent: () => import('./app/components/pages/add-computation/add-computation.component').then((m) => m.AddComputationComponent),
        canActivate: [AuthorizationGuard],
    },
    {
        path: 'edit/:id',
        loadComponent: () => import('./app/components/pages/edit-computation/edit-computation.component').then((m) => m.EditComputationComponent),
        canActivate: [AuthorizationGuard],
    },
    {
        path: 'login',
        loadComponent: () => import('./app/components/pages/login/login.component').then((m) => m.LoginComponent),
    },
    {
        path: 'logout',
        loadComponent: () => import('./app/components/pages/logout/logout.component').then((m) => m.LogoutComponent),
    },
    { path: '**', redirectTo: '' }
];

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule,HttpClientModule,BrowserAnimationsModule, ReactiveFormsModule, MatAutocompleteModule, MatFormFieldModule, MatInputModule,
          MatSelectModule, MatToolbarModule, MatButtonModule, MatTableModule, NgxMatDatetimePickerModule, NgxMatTimepickerModule,
          NgxMatNativeDateModule, FormsModule, MatCheckboxModule, MatCardModule, MatGridListModule, MatInputModule,
          MatSelectModule, NgxDaterangepickerMd.forRoot({
            applyLabel: 'Okay',
            firstDay: 0,
        }), MatOptionModule, TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        })),
        TranslateService, TranslatePipe, TranslateDirective, I18nService,
        { provide: SETTINGS, useValue: settings },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations(),
        provideRouter(routes),
        provideNoopAnimations()
    ],
})
  .catch((err) => console.error(err));
