<main *ngIf="!isLoginPage() && !isLogoutPage()">
  <toolbar [isMobile]="isMobile" (toggleSent)="toggleMenu()"></toolbar>
  <mat-sidenav-container id="links-container" autosize="true">
    <mat-sidenav [ngClass]="!isCollapsed ? 'expanded' : ''" [mode]="isMobile ? 'over' : 'side'" [opened]="isMobile ? 'false' : 'true'">
      <a>
<!--        <mat-nav-list routerLink="/home" class="menu-item" routerLinkActive="selected">-->
<!--            <mat-icon>house</mat-icon>-->
<!--            <span *ngIf="!isCollapsed">{{ "Home" | translate }}</span>-->
<!--            <div class="spacer"></div>-->
<!--            <mat-icon *ngIf="!isCollapsed">chevron_right</mat-icon>-->
<!--        </mat-nav-list>-->
        <mat-nav-list routerLink="/addSimple" class="menu-item" routerLinkActive="selected">
          <mat-icon>calculate</mat-icon>
          <span *ngIf="!isCollapsed">{{ "Simple" | translate }}</span>
          <div class="spacer"></div>
          <mat-icon *ngIf="!isCollapsed">chevron_right</mat-icon>
        </mat-nav-list>
        <mat-nav-list routerLink="/addAdvanced" class="menu-item" routerLinkActive="selected">
          <mat-icon>calculate</mat-icon>
          <span *ngIf="!isCollapsed">{{ "Advanced" | translate }}</span>
          <div class="spacer"></div>
          <mat-icon *ngIf="!isCollapsed">chevron_right</mat-icon>
        </mat-nav-list>
        <mat-nav-list routerLink="/computations" class="menu-item" routerLinkActive="selected">
          <mat-icon>list</mat-icon>
          <span *ngIf="!isCollapsed">{{ "Computations" | translate }}</span>
          <div class="spacer"></div>
          <mat-icon *ngIf="!isCollapsed">chevron_right</mat-icon>
        </mat-nav-list>
      </a>
<!--      <a>-->
<!--        <mat-nav-list routerLink="/logout" class="menu-item" routerLinkActive="selected">-->
<!--            <mat-icon>house</mat-icon>-->
<!--            <span *ngIf="!isCollapsed">{{ "Logout" | translate }}</span>-->
<!--            <div class="spacer"></div>-->
<!--            <mat-icon *ngIf="!isCollapsed">chevron_right</mat-icon>-->
<!--        </mat-nav-list>-->
<!--      </a>-->
<!-- position: fixed; bottom: 0; width: 100%; -->
      <div style="position: absolute; bottom:0; width: 100%;">
        <div><span style="padding-left: 6px;">v{{ versionPackage }}</span></div>
        <div class="bottom-elements">
          <div class="menu-item" style="cursor: pointer;" (click)="toggleMenu()">
            <div *ngIf="!isCollapsed" style="float: left; padding: 3px; font-size: 12px;">{{"menu.collapse" | translate | uppercase }}</div>
            <div class="spacer"></div>
            <mat-icon style="float: right;" *ngIf="!isCollapsed">chevron_left</mat-icon>
            <mat-icon *ngIf="isCollapsed">chevron_right</mat-icon>
          </div>
        </div>
      </div>
    </mat-sidenav>
    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</main>
<router-outlet *ngIf="isLoginPage() || isLogoutPage()"></router-outlet>

<!--<footer>Footer</footer>-->
